import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routings from './routes';
import { HelmetProvider } from 'react-helmet-async';
// import { AppContext } from './context/appContext';
// import { useRefetchUserPermission } from './Hooks/useGeneralHook';
function App() {
  // const { permissionArr, isLoadingPermission } = useRefetchUserPermission();
  return (
    <div className='App'>
      {/* <AppContext.Provider
        value={{
          permissionArr,
        }}
      > */}
      <HelmetProvider>
        {/* {!isLoadingPermission && ( */}
        <Router>
          <Routings />
        </Router>
        {/* )} */}
      </HelmetProvider>
      {/* </AppContext.Provider> */}
    </div>
  );
}

export default App;
