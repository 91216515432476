import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getProfile } from '../../Api/auth';
import { fileUploadToBucket } from '../../Api/fileUpload';
// import { ErrorToast } from '../../../Utils/SweetAlert';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';

const Profile = () => {
  const [profileData, setProfileData] = useState({});
  const [loader, setLoader] = useState(false);
  const getProfileData = async () => {
    getProfile()
      .then((res) => {
        setProfileData(res?.data?.data || {});
      })
      .catch(() => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  const uploadProfile = async (e) => {
    setLoader(true);
    const profilePic = e.target.files[0];
    const profilePostData = {
      user_id: profileData._id,
      file_name: profilePic.name,
      file_module: 'PROFILE_PICTURE',
      mime_type: profilePic.type,
      document_sides: 'NONE',
    };
    await fileUploadToBucket({ data: profilePostData, file: profilePic }).then(() => {
      getProfileData();
      setLoader(false);
      window.location.reload();
    });
    e.target.value = null;
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Admin Profile</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='row justify-content-lg-center'>
            <div className='col-lg-10'>
              <div className='page-header'>
                <div className='row'>
                  <div className='col'>
                    <h3 className='page-title'>Profile</h3>
                    <ul className='breadcrumb'>
                      <li className='breadcrumb-item'>
                        <Link to='/admin/dashboard'>Dashboard</Link>
                      </li>
                      <li className='breadcrumb-item active'>Profile</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='profile-cover'>
                <div className='profile-cover-wrap'>
                  <img
                    className='profile-cover-img'
                    src={profileData?.profile_picture || '/inner-assets/img/profiles/default_image.jpg'}
                    alt='Profile pic'
                  />
                  <div className='cover-content'></div>
                </div>
              </div>
              <div className='text-center mb-5'>
                <label className='avatar pro-avatar-xxl profile-cover-avatar' htmlFor='avatar_upload'>
                  <img
                    className='avatar-img'
                    src={profileData?.profile_picture || '/inner-assets/img/profiles/default_image.jpg'}
                    alt='Profile pic'
                  />
                  <input type='file' id='avatar_upload' onChange={(e) => uploadProfile(e)} />
                  <span className='avatar-edit'>
                    {loader && <i className='fas fa-spinner fa-spin'></i>}
                    {!loader && (
                      <i data-feather='edit-2' className='avatar-uploader-icon fa fa-pencil-alt shadow-soft' />
                    )}
                  </span>
                </label>
                <h2>
                  {profileData?.name || '-'} <i className='fas fa-certificate text-primary small' />
                </h2>
                <ul className='list-inline'>
                  {profileData?.company_name && (
                    <li className='list-inline-item mx-2'>
                      <i className='far fa-building mx-2' /> <span>{profileData?.company_name || '-'}</span>
                    </li>
                  )}
                  {profileData?.billing_address?.address && (
                    <li className='list-inline-item mx-2'>
                      <i className='fas fa-map-marker-alt mx-2' />
                      {profileData?.billing_address?.address ? `${profileData?.billing_address?.address}` : ''}
                      {profileData?.billing_address?.city
                        ? `, ${profileData?.billing_address?.city}-${profileData?.billing_address?.zip_code}`
                        : ''}
                      {profileData?.billing_address?.state ? `, ${profileData?.billing_address?.state}` : ''}
                      {profileData?.billing_address?.country ? `, ${profileData?.billing_address?.country}` : ''}
                    </li>
                  )}
                  {profileData?.createdAt && (
                    <li className='list-inline-item mx-2'>
                      <i className='far fa-calendar-alt mx-2' />
                      {moment(profileData?.createdAt).format('ll')}
                    </li>
                  )}
                </ul>
              </div>
              <div className='row'>
                <div className='col-lg-4'>
                  <div className='card'>
                    <div className='card-header'>
                      <h5 className='card-title d-flex justify-content-between'>
                        <span>Profile</span>
                        <Link className='btn btn-sm btn-white' to='/admin/setting'>
                          Edit
                        </Link>
                      </h5>
                    </div>
                    <div className='card-body'>
                      <ul className='list-unstyled mb-0'>
                        {profileData?.name && (
                          <li className='py-0'>
                            <strong className='text-dark'>About</strong>
                          </li>
                        )}
                        <li>{profileData?.name || ''}</li>
                        <li>
                          {profileData?.billing_address?.address ? `${profileData?.billing_address?.address}` : ''}
                          {profileData?.billing_address?.city
                            ? `, ${profileData?.billing_address?.city}-${profileData?.billing_address?.zip_code}`
                            : ''}
                          {profileData?.billing_address?.state ? `, ${profileData?.billing_address?.state},` : ''}
                          {profileData?.billing_address?.country ? `, ${profileData?.billing_address?.country}` : ''}
                        </li>
                        <li className='pt-2 pb-0'>
                          <strong className='text-dark'>Contacts</strong>
                        </li>
                        <li>{profileData?.email || ''}</li>
                        <li>{profileData?.mobile || ''}</li>
                        {profileData?.billing_address?.address && (
                          <li className='pt-2 pb-0'>
                            <strong className='text-dark'>Address</strong>
                          </li>
                        )}
                        <li>
                          {profileData?.billing_address?.address && (
                            <>
                              {profileData?.billing_address?.address},<br />
                            </>
                          )}
                          {profileData?.billing_address?.city && (
                            <>
                              {profileData?.billing_address?.city}-{profileData?.billing_address?.zip_code},<br />
                            </>
                          )}
                          {profileData?.billing_address?.state && (
                            <>
                              {profileData?.billing_address?.state},<br />
                            </>
                          )}
                          {profileData?.billing_address?.country && <>{profileData?.billing_address?.country}</>}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-lg-8'>
                  <div className='card'>
                    <div className='card-header'>
                      <h5 className='card-title'>Activity</h5>
                    </div>
                    <div className='card-body card-body-height'>
                      <ul className='activity-feed'>
                        <li className='feed-item'>
                          <div className='feed-date'>No Activity Found.</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='container-fluid absolute mb-4'>
              <footer className='text-center text-lg-start'>
                <div className='text-center'>Powered by Alsaffron System.</div>
              </footer>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
