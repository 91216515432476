import Api from '../Utils/Axios';

export const AddPermissionService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/permission', data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetPermissionPaginate = async ({ limit, page }) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get(`/permission?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  });
};
