import React, { useEffect, useState } from 'react';
import { useGetPermissions } from '../../../../../Hooks/useGeneralHook';

export const useBreadCrumbData = () => {
  const { permission_check } = useGetPermissions();
  const [breadCrumbData, setBreadCrumbData] = useState({
    title: 'Users',
    bread_crumbs: [
      {
        class: 'breadcrumb-item',
        label: 'Dashboard',
        path: '/admin/dashboard',
      },
      {
        class: 'breadcrumb-item active',
        label: 'Users',
      },
    ],
  });
  useEffect(() => {
    if (permission_check('user-add') === true) {
      setBreadCrumbData({
        ...breadCrumbData,
        header_action: {
          path: '/admin/add-user',
          children: (
            <React.Fragment>
              <i className='fas fa-plus' /> Add User
            </React.Fragment>
          ),
        },
      });
    }
  }, []);
  return { breadCrumbData };
};
