
import Api from '../Utils/Axios';

export const GetCustomerReturns = async (return_id) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get(`third-party/customerreturns/${return_id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetReturnProductAlternates = async (sku_id) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get(`third-party/productalternates?sku_id=${sku_id}`));
    } catch (error) {
      reject(error);
    }
  });
};
