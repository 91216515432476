import { useQuery } from '@tanstack/react-query';
import { adminGetUserPermissionService } from '../Api/user';
import { useContext } from 'react';
import { AppContext } from '../context/appContext';

export const useGetPermissions = () => {
  const { permissionArr } = useContext(AppContext);

  const permission_check = (permission) => {
    // console.log('permissionsArr', permissionArr);
    return permissionArr?.indexOf(permission) !== -1;
  };

  return { permission_check };
};

export const useRefetchUserPermission = () => {
  const { isFetching: isLoadingPermission, data: permissionArr } = useQuery({
    queryKey: ['get-user-login-permissions'],
    queryFn: () => adminGetUserPermissionService(),
    select: (res) => res?.data?.data?.permissions || [],
  });

  return { isLoadingPermission, permissionArr };
};
