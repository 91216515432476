import * as Yup from 'yup';

export const ResetPasswordInitialValues = {
  newPass: '',
  conformNewPass: '',
};

export const ResetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .test('uppderCase', 'Must contain at least one uppercase character', (value) => /[A-Z]/.test(value))
    .test('lowerCase', 'Must contain at least one lowercase character', (value) => /[a-z]/.test(value))
    .test('oneNumber', 'Must contain at least one number', (value) => /[0-9]/.test(value))
    .test('oneSpecial', 'Must contain at least one special character', (value) => /[!@#%&]/.test(value)),
  confirm_password: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
