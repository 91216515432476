import { useFormikContext } from 'formik';
import React, { useState } from 'react';

const PassowrdField = ({ field, placeholder }) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { handleChange } = useFormikContext();

  return (
    <div className='pass-group'>
      <input
        {...field}
        type={isShowPassword ? 'text' : 'password'}
        onChange={handleChange}
        className='input-customize'
        name={field?.name}
        placeholder={placeholder}
      />
      <span
        onClick={() => setIsShowPassword(!isShowPassword)}
        className={`fas ${isShowPassword ? 'fa-eye-slash' : 'fa-eye'} toggle-password`}
      />
    </div>
  );
};

export default PassowrdField;
