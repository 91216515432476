import React from 'react';
import { Link } from 'react-router-dom';

const BreadCrumb = ({ data }) => {
  return (
    <div className='page-header'>
      <div className='row align-items-center'>
        <div className='col'>
          <h3 className='page-title'>{data?.title || ''}</h3>
          <ul className='breadcrumb'>
            {data.bread_crumbs?.map((bread_crumb, index) => (
              <li key={`page-header-bread-crumb-index-${index}`} className={bread_crumb.class}>
                {bread_crumb?.path ? <Link to={bread_crumb.path}>{bread_crumb.label}</Link> : bread_crumb.label}
              </li>
            ))}
          </ul>
        </div>
        <div className='col-auto'>
          {data?.header_action && (
            <Link to={data?.header_action.path} className='btn btn-them-gray me-1'>
              {data?.header_action.children}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
