import React from 'react';

export const breadCrumbData = {
  title: <React.Fragment>Add Role &amp; Permissions</React.Fragment>,
  bread_crumbs: [
    {
      class: 'breadcrumb-item',
      label: 'Dashboard',
      path: '/admin/dashboard',
    },
    {
      class: 'breadcrumb-item',
      label: <React.Fragment>Role &amp; Permissions</React.Fragment>,
      path: '/admin/roles',
    },
    {
      class: 'breadcrumb-item active',
      label: <React.Fragment>Add Role &amp; Permissions</React.Fragment>,
    },
  ],
  header_action: {
    path: '/admin/roles',
    children: (
      <React.Fragment>
        <i className='fas fa-angle-double-left' /> Back
      </React.Fragment>
    ),
  },
};
