import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { RoleInitialValues, RoleValidationSchema } from '../../constant';
import InputWithLabel from '../../../../Components/InputWithLabel';
import CheckboxWithLabel from '../../../../Components/CheckboxWithLabel';
import { useCreateRole, useGetPermission } from '../../hooks/useRoleManagement';
import HelmetComponent from '../../../../Components/HelmetComponent';
import BreadCrumb from '../../../../Components/BreadCrumb';
import { breadCrumbData } from './constant';

const AddRoleManagement = () => {
  const { permissions, isLoadingPermissionList } = useGetPermission();
  const { handleSubmitAddRoleForm } = useCreateRole();

  return (
    <React.Fragment>
      <HelmetComponent title='Admin Add Role Management' />
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <BreadCrumb data={breadCrumbData} />
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <Formik
                  initialValues={RoleInitialValues}
                  validationSchema={RoleValidationSchema}
                  validateOnChange
                  onSubmit={handleSubmitAddRoleForm}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className='card-header'>
                        <h5 className='card-title'>Role Information</h5>
                      </div>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <Field
                              type='text'
                              className={`form-control`}
                              name='role_name'
                              placeholder='Enter role name'
                              Label='Role Name'
                              component={InputWithLabel}
                            />
                          </div>
                          <div className='col-md-6'>
                            <Field
                              type='text'
                              className={`form-control`}
                              name='description'
                              placeholder='Enter description'
                              Label='Description'
                              component={InputWithLabel}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='card-header'>
                        <h5 className='card-title'>Permissions Information</h5>
                      </div>
                      <div className='card-body'>
                        <div className='row my-4'>
                          {isLoadingPermissionList === true ? (
                            <React.Fragment>
                              <h3>Loading...</h3>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {permissions.map((permission, index) => (
                                <div className='col-xl-3 col-sm-6 col-12' key={index}>
                                  <Field
                                    type='checkbox'
                                    className={`form-control`}
                                    name='rights'
                                    Label={permission.label}
                                    value={permission?.value}
                                    component={CheckboxWithLabel}
                                  />
                                </div>
                              ))}
                              <ErrorMessage name='rights' component='span' className='text-danger' />
                            </React.Fragment>
                          )}
                        </div>
                        <div className='text-end mt-4'>
                          <button type='submit' className='btn btn-them-gray btnstl' disabled={isSubmitting}>
                            Add Role
                            {isSubmitting && <i className='fas fa-spinner fa-spin'></i>}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddRoleManagement;
