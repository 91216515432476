import React from 'react';
import { Formik, Form, Field } from 'formik';
import { PermissionInitialValues, PermissionValidationSchema } from '../../constant';
import { useCreatePermission } from '../../hooks/usePermissionManagement';
import { useGetPermissions } from '../../../../Hooks/useGeneralHook';

const AddPermission = ({ refetchPermission }) => {
  const { permission_check } = useGetPermissions();
  const { handleSubmitCreatePermissionForm, isLoading } = useCreatePermission(refetchPermission);

  return (
    <React.Fragment>
      {permission_check('permission-add') && (
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-body'>
                <Formik
                  enableReinitialize
                  initialValues={PermissionInitialValues}
                  validationSchema={PermissionValidationSchema}
                  validateOnChange
                  onSubmit={handleSubmitCreatePermissionForm}
                >
                  <Form>
                    <div className='row align-items-center'>
                      <div className='col-md-6'>
                        <Field
                          type='text'
                          name='name'
                          className='input-customize'
                          placeholder='Enter permission name'
                          Label='Permission Name'
                        />
                      </div>
                      <div className='col-md-6'>
                        <div className='text-start'>
                          <button type='submit' className='btn btn-them-gray btnstl' disabled={isLoading}>
                            + Add Permission
                            {isLoading && <i className='fas fa-spinner fa-spin'></i>}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AddPermission;
