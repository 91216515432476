import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { AddUserValidationSchema, UserInitialValues } from '../../constant';
import InputWithLabel from '../../../../Components/InputWithLabel';
import SelectBox from '../../../../Components/SelectBox';
import HelmetComponent from '../../../../Components/HelmetComponent';
import { useCreateUser, useGetRole } from '../../hooks/useUserManagement';
import BreadCrumb from '../../../../Components/BreadCrumb';
import { breadCrumbData } from './constant';

const AddUser = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const { roleList, isLoadingRoleList } = useGetRole();
  const { handleSubmitAddUserForm } = useCreateUser();

  return (
    <React.Fragment>
      <HelmetComponent title='Add User' />
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <BreadCrumb data={breadCrumbData} />
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-body'>
                  <h4 className='card-title text-white'>Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={UserInitialValues}
                    validationSchema={AddUserValidationSchema}
                    validateOnChange
                    onSubmit={handleSubmitAddUserForm}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <Form>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <Field
                                type='text'
                                className={`form-control`}
                                name='first_name'
                                placeholder='Enter first name'
                                Label='First Name'
                                component={InputWithLabel}
                              />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <Field
                                type='text'
                                className={`form-control`}
                                name='last_name'
                                placeholder='Enter last name'
                                Label='Last Name'
                                component={InputWithLabel}
                              />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <Field
                                type='email'
                                Label='Email'
                                className={`form-control`}
                                name='email'
                                placeholder='Enter email'
                                component={InputWithLabel}
                              />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <Field
                                component={SelectBox}
                                label='Role'
                                name='roles'
                                options={roleList}
                                isMulti
                                isLoading={isLoadingRoleList}
                              />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <div className='inputWrap'>
                                <Field
                                  type={isShowPassword ? 'text' : 'password'}
                                  className={`form-control ${
                                    errors.password && touched.password ? 'input-error' : null
                                  }`}
                                  name='password'
                                  placeholder='Enter password'
                                  Label='Password'
                                  component={InputWithLabel}
                                />
                                <span
                                  onClick={() => setIsShowPassword(!isShowPassword)}
                                  className={`fas ${isShowPassword ? 'fa-eye-slash' : 'fa-eye'} toggle-password-user`}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <div className='inputWrap'>
                                <Field
                                  type={isShowConfirmPassword ? 'text' : 'password'}
                                  className={`form-control ${
                                    errors.confirm_password && touched.confirm_password ? 'input-error' : null
                                  }`}
                                  name='confirm_password'
                                  placeholder='Confirm password'
                                  Label='Confirm Password'
                                  component={InputWithLabel}
                                />
                                <span
                                  onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                                  className={`fas ${
                                    isShowConfirmPassword ? 'fa-eye-slash' : 'fa-eye'
                                  } toggle-password-user`}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='text-end mt-4'>
                          <button type='submit' className='btn btn-them-gray btnstl' disabled={isSubmitting}>
                            Add User
                            {isSubmitting && <i className='fas fa-spinner fa-spin'></i>}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddUser;
