// import { useMutation } from '@tanstack/react-query';
// import { ErrorToast } from '../../../Utils/SweetAlert';
import { GetReturnProductAlternates } from '../../../Api/customerReturns';
import { useState } from 'react';
// import { CustomerReturnAPIResponse } from '../constant';

export const useProductAlternatesManagement = () => {
  const [returnResponse, setReturnResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmitFetchAlternateData = (evt) => {
    setIsLoading(true);
    GetReturnProductAlternates(evt.sku_id)
    .then((resp)=>{
      setIsLoading(false);
      setReturnResponse(resp.data?.data);
    })
    .catch((error)=>{
      setIsLoading(false);
      console.log("handleSubmitFetchAlternateData error", error);
    })
  };

  return { handleSubmitFetchAlternateData, isLoading, returnResponse };
};
