import Swal from 'sweetalert2';
import Success from '../assets/Images/Success.png';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const SuccessToast = (message) =>
  Toast.fire({
    // icon: 'success',
    iconHtml: `<img src=${Success} alt='success' width='70px'>`,
    title: message,
    customClass: {
      iconHtml: 'no-border',
    },
  });

export const ErrorToast = (message) =>
  Toast.fire({
    icon: 'error',
    title: message,
  });

export const WarningToast = (message) =>
  Toast.fire({
    icon: 'warning',
    title: message,
  });

export const InfoToast = (message) =>
  Toast.fire({
    icon: 'info',
    title: message,
  });

export const Confirmation = (message) =>
  Swal.fire({
    title: 'Are you sure?',
    icon: 'warning',
    text: message,
    buttons: true,
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonText: 'Yes',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    reverseButtons: true,
  });

export const TextArea = async ({ denyButtonText = 'Cancel' }) =>
  await Swal.fire({
    input: 'textarea',
    name: 'reason',
    inputLabel: 'Message',
    inputPlaceholder: 'Type your reason here...',
    inputAttributes: {
      'aria-label': 'Type your message here',
    },
    confirmButtonText: 'Reject',
    denyButtonText: denyButtonText,
    showDenyButton: true,
    confirmButtonColor: '#dc3741',
    denyButtonColor: '#3085d6',
    showCancelButton: false,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    preConfirm: (msg) => {
      if (!msg) {
        Swal.showValidationMessage(`Please enter reason`);
      }
    },
  });

export const RejectTextArea = async ({ denyButtonText = 'Cancel' }) =>
  await Swal.fire({
    input: 'textarea',
    name: 'reason',
    inputLabel: 'Reject reason',
    inputPlaceholder: 'Type your reason here...',
    inputAttributes: {
      'aria-label': 'Type your message here',
    },
    confirmButtonText: 'Reject',
    denyButtonText: denyButtonText,
    showDenyButton: true,
    confirmButtonColor: '#dc3741',
    denyButtonColor: '#3085d6',
    showCancelButton: false,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    preConfirm: (msg) => {
      if (!msg) {
        Swal.showValidationMessage(`Please enter reason`);
      }
    },
  });
