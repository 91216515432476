import { useMutation, useQuery } from '@tanstack/react-query';
import { Confirmation, ErrorToast, SuccessToast } from '../../../Utils/SweetAlert';
import {
  ActiveInactiveUser,
  AddUserService,
  DeleteUserById,
  EditUserService,
  GetUserByIdService,
  GetUserPaginate,
} from '../../../Api/user';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetRoleListService } from '../../../Api/role';

export const useGetUserPagination = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const {
    isFetching: isLoadingUserList,
    data: usersList,
    refetch: refetchUserList,
  } = useQuery({
    queryKey: ['get-insight-list'],
    queryFn: () =>
      GetUserPaginate({
        limit,
        page,
      }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || 'Server error.');
    },
  });

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    refetchUserList();
  }, [limit, page]);

  return { usersList, isLoadingUserList, refetchUserList, handlePageChange, handleLimitChange, limit, page };
};

export const useDeleteUser = (refetchUserList) => {
  const userDeleteMutate = useMutation({
    mutationFn: (data) => DeleteUserById(data),
  });
  const handleDelete = (userId) => {
    Confirmation('Want to delete user?').then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        userDeleteMutate.mutate(userId, {
          onSuccess: (res) => {
            SuccessToast(res?.data?.message || 'User Deleted.');
            refetchUserList();
          },
          onError: (err) => {
            if (typeof err.response.data.message !== 'undefined') {
              ErrorToast(err.response.data.message || 'Server Error!!');
            } else {
              ErrorToast(err?.message || 'Server Error!!');
            }
          },
        });
      }
    });
  };
  return { handleDelete };
};

export const useChangeStatusUser = (refetchUserList) => {
  const userStatusChangeMutate = useMutation({
    mutationFn: (data) => ActiveInactiveUser(data),
  });
  const handleChangeStatus = (user, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          id: user._id,
          is_active: !user.is_active,
        };
        userStatusChangeMutate.mutate(postObj, {
          onSuccess: (res) => {
            SuccessToast(res?.data.message || 'Status Updated');
            refetchUserList();
          },
          onError: (err) => {
            if (typeof err.response.data.message !== 'undefined') {
              ErrorToast(err.response.data.message || 'Server Error!!');
            } else {
              ErrorToast(err?.message || 'Server Error!!');
            }
          },
        });
      }
    });
  };
  return { handleChangeStatus };
};

export const useCreateUser = () => {
  const navigate = useNavigate();
  const userCreateMutate = useMutation({
    mutationFn: (data) => AddUserService(data),
  });
  const handleSubmitAddUserForm = (values) => {
    const postData = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      roles: values.roles.map((role) => role.value),
      is_active: true,
    };
    userCreateMutate.mutate(postData, {
      onSuccess: (res) => {
        SuccessToast(res?.data?.message || 'User created successfully.');
        navigate('/admin/users');
      },
      onError: (err) => {
        if (typeof err.response.data.message !== 'undefined') {
          ErrorToast(err.response.data.message || 'Server Error!!');
        } else {
          ErrorToast(err?.message || 'Server Error!!');
        }
      },
    });
  };
  return { handleSubmitAddUserForm };
};

export const useUpdateUser = () => {
  const stateData = useLocation();
  const { id } = stateData?.state || '';
  const navigate = useNavigate();
  const userUpdateMutate = useMutation({
    mutationFn: (data) => EditUserService(data),
  });
  const handleSubmitEditUserForm = (values) => {
    const postObj = {
      id,
      first_name: values.first_name,
      last_name: values.last_name,
      roles: values.roles.map((role) => role.value),
    };
    userUpdateMutate.mutate(postObj, {
      onSuccess: (res) => {
        SuccessToast(res?.data?.message || 'User updated successfully.');
        navigate('/admin/users');
      },
      onError: (err) => {
        if (typeof err.response.data.message !== 'undefined') {
          ErrorToast(err.response.data.message || 'Server Error!!');
        } else {
          ErrorToast(err?.message || 'Server Error!!');
        }
      },
    });
  };
  return { handleSubmitEditUserForm };
};

export const useGetRole = () => {
  const {
    data: roleList,
    isFetching: isLoadingRoleList,
    refetch: refetchRoleList,
  } = useQuery({
    queryKey: ['get-role-list'],
    queryFn: () => GetRoleListService(),
    select: (res) => res?.data?.data.map((role) => ({ label: role.name, value: role._id })),
    throwOnError: (err) => {
      if (typeof err.response.data.message !== 'undefined') {
        ErrorToast(err.response.data.message || 'Server Error!!');
      } else {
        ErrorToast(err?.message || 'Server Error!!');
      }
    },
  });
  return { roleList, isLoadingRoleList, refetchRoleList };
};

export const useGetSingleUser = (formRef) => {
  const stateData = useLocation();
  const { id } = stateData?.state || '';
  const { data: userData } = useQuery({
    queryKey: ['get-single-user-data'],
    enabled: !!id,
    queryFn: () => GetUserByIdService(id),
    throwOnError: (err) => {
      if (typeof err.response.data.message !== 'undefined') {
        ErrorToast(err.response.data.message || 'Server Error!!');
      } else {
        ErrorToast(err?.message || 'Server Error!!');
      }
    },
  });
  useEffect(() => {
    if (userData && formRef?.current?.values) {
      formRef?.current?.setValues({
        first_name: userData.data?.data?.first_name || '',
        last_name: userData.data?.data?.last_name || '',
        email: userData.data?.data?.email || '',
        roles: userData.data?.data?.roles?.map((role) => ({ label: role.role.name, value: role.role._id })),
      });
    }
  }, [userData]);
  return { userData };
};
