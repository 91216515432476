import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CustomerReturnsInitialValues, CustomerReturnsValidationSchema } from '../../constant';

const FetchReturns = ({ handleSubmitFetchCustomerReturnsForm, isLoading }) => {
  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-md-12'>
          <div className='card'>
            <div className='card-body'>
              <Formik
                enableReinitialize
                initialValues={CustomerReturnsInitialValues}
                validationSchema={CustomerReturnsValidationSchema}
                validateOnChange
                onSubmit={handleSubmitFetchCustomerReturnsForm}
              >
                <Form>
                  <div className='row align-items-center'>
                    <div className='col-md-6'>
                      <Field
                        type='text'
                        name='return_id'
                        className='input-customize'
                        placeholder='Enter return ID'
                        label='Return ID'
                      />
                      <ErrorMessage name='return_id' component='span' className='text-danger' />
                    </div>
                    <div className='col-md-6'>
                      <div className='text-start'>
                        <button type='submit' className='btn btn-them-gray btnstl' disabled={isLoading}>
                          Fetch Return Data &nbsp;
                          {isLoading && <i className='fas fa-spinner fa-spin'></i>}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FetchReturns;
