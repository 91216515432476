import * as Yup from 'yup';

export const UserInitialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirm_password: '',
  roles: [],
};

export const AddUserValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  roles: Yup.array().min(1, 'Please select role').required('Please select role'),
  password: Yup.string()
    .required('Password is required')
    .test('uppderCase', 'Must contain at least one uppercase character', (value) => /[A-Z]/.test(value))
    .test('lowerCase', 'Must contain at least one lowercase character', (value) => /[a-z]/.test(value))
    .test('oneNumber', 'Must contain at least one number', (value) => /[0-9]/.test(value))
    .test('oneSpecial', 'Must contain at least one special character', (value) => /[!@#%&]/.test(value)),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const EditUserValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  roles: Yup.array().min(1, 'Please select role').required('Please select role'),
});
