export const breadCrumbData = {
  title: 'Customer Return List',
  bread_crumbs: [
    {
      class: 'breadcrumb-item',
      label: 'Dashboard',
      path: '/admin/dashboard',
    },
    {
      class: 'breadcrumb-item active',
      label: 'Customer Return List',
    },
  ],
};
