import React from 'react';
import { useFormikContext } from 'formik';
import './style.css';

const CheckboxWithLabel = ({ field, placeholder, Label, value, defaultChecked = false }) => {
  const { handleChange } = useFormikContext();

  return (
    <div className='form-group'>
      <label className='text-white'>
        <input
          {...field}
          type='checkbox'
          value={value}
          onChange={handleChange}
          name={field.name}
          placeholder={placeholder}
          defaultChecked={defaultChecked}
        />
        &nbsp;&nbsp;&nbsp;
        {Label}
      </label>
    </div>
  );
};

export default CheckboxWithLabel;
