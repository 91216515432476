import React, { useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { EditUserValidationSchema, UserInitialValues } from '../../constant';
import InputWithLabel from '../../../../Components/InputWithLabel';
import SelectBox from '../../../../Components/SelectBox';
import { useGetRole, useGetSingleUser, useUpdateUser } from '../../hooks/useUserManagement';
import HelmetComponent from '../../../../Components/HelmetComponent';
import BreadCrumb from '../../../../Components/BreadCrumb';
import { breadCrumbData } from './constant';

const EditUser = () => {
  const formRef = useRef();
  const { roleList, isLoadingRoleList } = useGetRole();
  const { handleSubmitEditUserForm } = useUpdateUser();
  useGetSingleUser(formRef);

  return (
    <React.Fragment>
      <HelmetComponent title='Edit User' />
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <BreadCrumb data={breadCrumbData} />
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-body'>
                  <h4 className='card-title text-white'>Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={UserInitialValues}
                    validationSchema={EditUserValidationSchema}
                    innerRef={formRef}
                    onSubmit={handleSubmitEditUserForm}
                  >
                    {({ isSubmitting }) => {
                      return (
                        <Form>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <Field
                                  type='text'
                                  className={`form-control`}
                                  name='first_name'
                                  placeholder='Enter first name'
                                  Label='First Name'
                                  component={InputWithLabel}
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <Field
                                  type='text'
                                  className={`form-control`}
                                  name='last_name'
                                  placeholder='Enter last name'
                                  Label='Last Name'
                                  component={InputWithLabel}
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <Field
                                  type='email'
                                  Label='Email'
                                  className={`form-control`}
                                  name='email'
                                  placeholder='Enter email'
                                  component={InputWithLabel}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <Field
                                  component={SelectBox}
                                  name='roles'
                                  options={roleList}
                                  isMulti
                                  isLoading={isLoadingRoleList}
                                  Label='Role'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='text-end mt-4'>
                            <button type='submit' className='btn btn-them-gray btnstl' disabled={isSubmitting}>
                              Update User
                              {isSubmitting && <i className='fas fa-spinner fa-spin'></i>}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditUser;
